import React from 'react';
import PropTypes from 'prop-types';

const InputWithSuffix = ({
    name, placeholder, label, suffix, className, required, type, ...rest
}) => (
    <div className={`form-field form-suffixed form-text ${className}`}>
        <label htmlFor={name} className="text-label">{label}</label>
        <div className="form-suffixed__wrapper">
            <input {...rest} id={name} name={name} type={type} step={rest.step || 'any'} placeholder={placeholder} className="form-suffixed__input form-text__input" required={required} />
            <div className="form-suffixed__suffix">
                <span>{suffix}</span>
            </div>
        </div>

    </div>
);

InputWithSuffix.defaultProps = {
    placeholder: '',
    className: '',
    type: 'text'
};

InputWithSuffix.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    suffix: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
};

export default InputWithSuffix;
