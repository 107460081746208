import React from 'react';
import { Link } from 'gatsby';

import Layout from '@layouts/Layout';

import Alert from '@components/_ui/Alert';
import PlannerForm from '@components/PlannerForm';

const Planner = () => (
    <Layout metaData={{ title: 'Planner your bespoke pergola' }}>
        <header className="hero">
            <div className="container flow--large">
                <h1 className="text-page-title">Bespoke Designs</h1>
                <div className="flow">
                    <h2 className="text-subtitle">Hello and welcome to our new design service - Pergola Planner&trade;</h2>
                    <p className="text-paragraph">Please use the forms below to let us know your requirements and specifications and we will get back to you with a quote to have your own bespoke pergola or arbour for your garden.</p>
                </div>
            </div>
        </header>
        <section className="container island--xlarge text-align--center flow--medium">
            <h2 className="text-subtitle">How it works</h2>
            <div>
                <div className="grid-x grid-margin-x grid-margin-y">
                    <div className="cell medium-6 large-4 flow">
                        <span className="counter counter--centered">1</span>
                        <p className="text-paragraph text-paragraph--small">Upload your own drawings, sketches and pictures and let us do the rest</p>
                    </div>
                    <div className="cell medium-6 large-4 flow">
                        <span className="counter counter--centered">2</span>
                        <p className="text-paragraph text-paragraph--small">Using our existing materials (i.e. no curves or arches, we can only produce square or rectangular shapes) we will create a structure that meets with your approval</p>
                    </div>
                    <div className="cell medium-6 large-4 flow">
                        <span className="counter counter--centered">3</span>
                        <p className="text-paragraph text-paragraph--small">If your design is not possible we will work with you to find a solution</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="container backdrop backdrop--bottom-half">
            <Alert type="warning">
                <span className="text-label">Please note</span>
                <p className="text-paragraph text-paragraph--small">Given the timber section we work with for the rafters (38mm x 125mm) <strong>the longest unsupported 'span width' is 3.5 metres for the 24hr high pressure treated timber and 4mtrs for oak.</strong> This is to prevent any future bowing of the rafters as the years go by. For further information, please refer back to <Link to="/pergola-planning/general-information-about-our-pergolas/" className="text-link" title="Read more information on wood choice">Pergola Information.</Link> <strong>We can only produce square or rectangular shapes.</strong></p>
            </Alert>
        </section>
        <section className="wrapper background-color--chalk">
            <div className="container flow--medium">
                <h1 className="text-subtitle">Pergola or Arbour details</h1>
                <p className="text-paragraph">Whether you send us a sketch on the back of a napkin, or a carefully measured blueprint, we’ll work with you to create your Perfect Pergola. Please be aware that not all things are possible but we will certainly give you a product that will try our very best to get as close to your original idea as we can. We look forward to receiving your instructions and will be back in touch very shortly. Many thanks!</p>
                <PlannerForm />
            </div>
        </section>
    </Layout>
);

export default Planner;
