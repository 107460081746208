import axios from 'axios';

export default {
    submitPlanner: async (formData, url) => {
        const formDataObj = {};
        formData.forEach((value, key) => { formDataObj[key] = value; });

        const response = axios.post(url, formDataObj);

        return response;
    }
};
