import React, { useState } from 'react';

const PhotoUpload = ({ name }) => {
    const [photoUrl, setPhotoUrl] = useState(null);

    const onChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file.size > 10000000) { // 10mb ish - api allows 50, but mailgun is limited as are other mailboxes.
            alert('File is too big, please select another or resize the image!');
            e.target.value = '';
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
            const b64 = reader.result.replace(/^data:.+;base64,/, '');
            setPhotoUrl(b64);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="form-field">
            <input type="file" id={name} name={`file--${name}`} onChange={onChange} className="custom-file-input__input" accept="image/*" />
            {photoUrl && <input type="hidden" name={name} value={photoUrl} />}
        </div>
    );
};

PhotoUpload.defaultProps = {

};

export default PhotoUpload;
