import React from 'react';

const DesignTypeRadioInput = ({
    id, name, label, imgUrl, onChange, value, checked, required, ...rest
}) => (
    <div className="form-field form-radio form-field--design">
        <input {...rest} id={id} name={name} type="radio" className="form-radio__input" onChange={onChange} value={value} checked={checked} required={required} />
        <label htmlFor={id} className="form-radio__label flex--column flow--small">
            <img src={imgUrl} alt={label} className="module-card__image" />
            <span>{label}</span>
        </label>
    </div>
);

DesignTypeRadioInput.defaultProps = {

};

export default DesignTypeRadioInput;
