import React from 'react';

const TextArea = ({ name, placeholder }) => (
    <div className="form-textarea">
        <textarea name={name} placeholder={placeholder} className="form-textarea__input" />
    </div>
);

TextArea.defaultProps = {
    name: '',
    placeholder: '',
};

export default TextArea;
