import React from 'react';

import Icon from '@components/_ui/Icon';

const FixingTypeRadioInput = ({
    id, name, label, iconId, onChange, value, checked, required, ...rest
}) => (
    <div className="form-field form-radio form-field--fixing">
        <input {...rest} id={id} name={name} type="radio" className="form-radio__input" onChange={onChange} value={value} checked={checked} required={required} />
        <label htmlFor={id} className="form-radio__label">
            <Icon id={iconId} className="icon--large icon--steelbook" />
            <span>{label}</span>
        </label>
    </div>
);

FixingTypeRadioInput.defaultProps = {

};

export default FixingTypeRadioInput;
