import React from 'react';

import Icon from '@components/_ui/Icon';

const WoodTypeRadioInput = ({
    id, name, label, iconId, onChange, value, checked, priceDiff, required, ...rest
}) => (
    <div className="form-field form-radio form-field--wood">
        <input
            {...rest}
            id={id}
            name={name}
            type="radio"
            className="form-radio__input"
            onChange={onChange}
            value={value}
            checked={checked}
            required={required}
        />
        <label htmlFor={id} className="form-radio__label">
            <Icon id={iconId} className="icon--large icon--steelbook" />
            <span>{label}</span>
            {typeof priceDiff !== 'undefined' && <span style={{ color: 'green' }}>£{priceDiff}</span>}
        </label>
    </div>
);

export default WoodTypeRadioInput;
