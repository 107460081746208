import React, { useState } from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

// import CheckboxInput from '@components/forms/CheckboxInput';
import TextInput from '@components/forms/TextInput';
import EmailInput from '@components/forms/EmailInput';
import TelephoneInput from '@components/forms/TelephoneInput';
import InputWithSuffix from '@components/forms/InputWithSuffix';
import FixingTypeRadioInput from '@components/forms/FixingTypeRadioInput';
import WoodTypeRadioInput from '@components/forms/WoodTypeRadioInput';
import DesignTypeRadioInput from '@components/forms/DesignTypeRadioInput';
import PhotoUpload from '@components/forms/PhotoUpload';
import TextArea from '@components/forms/TextArea';
import plannerService from '../data/services/planner.service';
import Alert from './_ui/Alert';

const PlannerForm = () => {
    const [submitTryCount, setSubmitTryCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return false;

        setIsLoading(true);
        setSubmitTryCount((state) => state + 1);

        let response;
        const form = e.currentTarget;

        /** After submit of worldpay iframe form. */
        const formData = new FormData(form);
        try {
            response = await plannerService.submitPlanner(formData, form.action);
        } catch (err) {
            console.error(err);
        }

        if (response && response.data.success) {
            window.location.href = '/planner/success';
        } else {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setError('There was an unknown error, please try again or contact us.');
        }

        return true;
    };
    return (
        <form
            action="/.netlify/functions/payment/planner-form-email"
            name="form-planner"
            className={`flow--medium ${submitTryCount > 0 ? '_js_form-submit-tried' : ''}`}
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={onSubmit}
        >
            { error && (
                <section className="cell small-12">
                    <Alert type="error">
                        <span className="text-label">Form submit failed.</span>
                        <p className="text-paragraph text-paragraph--small">{error }</p>
                    </Alert>
                </section>
            )}
            <input type="hidden" name="bot-field" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Preferred design</h3>
                        <p className="text-metadata">Please select your preferred design. For more information please refer to our  <Link to="/pergola-assembly/how-it-works/" className="text-link" title="Pergola assembly">lean-to assembly guide</Link> or <Link to="/arbour-assembly/how-it-works-arbour/" className="text-link" title="Arbour assembly">stand alone assembly guide</Link>.</p>
                    </aside>
                    <fieldset className="form-section__fields cluster--tight">
                        <div className="form-row">
                            <DesignTypeRadioInput required id="design-type--lean" name="design-type" label="Standard 'Lean-to'" imgUrl="/img/modules/module-one.jpg" value="lean-to" defaultChecked />
                            <DesignTypeRadioInput required id="design-type--stand" name="design-type" label="Traditional 'Stand-alone'" imgUrl="/img/modules/module-four.jpg" value="stand-alone" />
                        </div>
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Preferred dimensions</h3>
                        <p className="text-metadata">Please enter your preferred dimensions - <strong>taken to the outside of the posts</strong> - in metres and centimetres. Maximum post height is 3 metres.</p>
                    </aside>
                    <fieldset className="form-section__fields cluster--tight">
                        <div className="form-row">
                            <InputWithSuffix type="number" required name="dimension_length" placeholder="e.g. 7.5" label="Length*" suffix="Metres" className="form-field--measurement" />
                            <InputWithSuffix type="number" required name="dimension_width" placeholder="e.g. 3.2" label="Width*" suffix="Metres" className="form-field--measurement" />
                            <InputWithSuffix type="number" required name="dimension_height" placeholder="e.g. 2.85" label="height*" suffix="Metres" className="form-field--measurement" />
                        </div>
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Type of wood</h3>
                        <p className="text-metadata">For further information on wood choice, please refer to <Link to="/pergola-planning/general-information-about-our-pergolas/" className="text-link" title="Pergola Information">Pergola Information.</Link></p>
                    </aside>
                    <fieldset className="form-section__fields cluster--tight">
                        <div className="form-row">
                            <WoodTypeRadioInput required id="wood-type--softwood" name="wood-type" label="24hr High Pressure Treated timber - planed & chamfered" iconId="smooth-wood" value="softwood" defaultChecked />
                            <WoodTypeRadioInput required id="wood-type--hardwood" name="wood-type" label="Hardwood - Oak" iconId="wood" value="hardwood" />
                        </div>
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Type of post fixing</h3>
                        <p className="text-metadata">For further information on fixing choice, please refer to <Link to="/pergola-planning/general-information-about-our-pergolas/" className="text-link" title="Pergola Information">Pergola Information.</Link></p>
                    </aside>
                    <fieldset className="form-section__fields cluster--tight">
                        <div className="form-row">
                            <FixingTypeRadioInput required id="fixing-type--concrete" name="fixing-type" label="Concrete fixing" iconId="concrete-fixing" value="concrete" defaultChecked />
                            <FixingTypeRadioInput required id="fixing-type--bolt" name="fixing-type" label="Bolt-down fixing" iconId="bolt-fixing" value="bolt" />
                            <FixingTypeRadioInput required id="fixing-type--spike" name="fixing-type" label="Spike fixing" iconId="spike-fixing" value="spike" />
                        </div>
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Upload Example</h3>
                        <p className="text-metadata">Please upload from your computer an example drawing, photograph, or sketch of what you would like to see our designers try to emulate.</p>
                        <p className="text-metadata"><strong>Please note we can only produce square or rectangular shapes.</strong></p>
                    </aside>
                    <fieldset className="form-section__fields">
                        <PhotoUpload name="photos" placeholder="Click here, or drag a file here, to upload" />
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Special instructions</h3>
                        <p className="text-metadata">Please describe any information that you think would be of help to our designers.</p>
                        <p className="text-metadata"><strong>Please note we can only produce square or rectangular shapes.</strong></p>
                    </aside>
                    <fieldset className="form-section__fields">
                        <TextArea name="special-instructions" placeholder="e.g. any boundaries, walls, fences, ground conditions or overhanging trees that may prove a hindrance to the structure once assembled." />
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="form-section">
                <div>
                    <aside className="form-section__aside">
                        <h3 className="text-subheading">Personal Details</h3>
                        <p className="text-metadata">Please leave some details so we can get back to you about your ideas.</p>
                    </aside>
                    <fieldset className="form-section__fields flow">
                        <TextInput required name="name" placeholder="i.e. John David Doe" label="Name*" className="form-field--name" />
                        <div className="flow--small">
                            <TextInput required name="address-one" placeholder="House name or number, street name" label="Address*" className="form-field--address" />
                            <TextInput name="address-two" placeholder="Town or city, county" label="Address 2*" hideLabel className="form-field--address" />
                        </div>
                        <TextInput required name="postcode" placeholder="e.g. AB12 3CD" label="Postcode*" className="form-field--postcode" />
                        <div className="cluster--tight">
                            <div className="form-row">
                                <EmailInput required name="email" placeholder="e.g. hello@email.com" label="Email address*" className="form-field--email" />
                                <TelephoneInput name="telephone" placeholder="e.g. 01234 567 890" label="Telephone" className="form-field--telephone" />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <hr className="sep sep--light" />
            <div className="flex--justify">
                {/* <CheckboxInput name="gdpr-acceptance" label="GDPR or something?" /> */}
                <button
                    type="submit"
                    className="button"
                    onClick={() => { setSubmitTryCount((state) => state + 1); }}
                >
                    <span>{isLoading ? 'Sending...' : 'Send request'}</span>
                    <Icon id="arrow-right" className="button__icon" />
                </button>
            </div>
        </form>
    );
};

export default PlannerForm;
